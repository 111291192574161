import React, { useState, useEffect } from 'react';
import { FiMoon, FiSun } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import stratumLogo from "../media/Stratum-1.png"; 

const TopNav: React.FC = () => {
    const [activeTab, setActiveTab] = useState<string>('/');
    const [theme, setTheme] = useState<'light' | 'dark'>(
        sessionStorage.getItem('theme') as 'light' | 'dark' ||
        (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')
    );

    useEffect(() => {
        const root = window.document.documentElement;
        root.classList.remove(theme === 'dark' ? 'light' : 'dark');
        root.classList.add(theme);
        sessionStorage.setItem('theme', theme);
    }, [theme]);
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [activeTab]);

    const toggleTheme = () => {
        setTheme((prevTheme: 'light' | 'dark') => (prevTheme === 'light' ? 'dark' : 'light'));
    };
    
    const tabs = [
        { id: 'home', label: 'Home', href: '/' },
        // { id: 'about', label: 'About', href: '/about' },
        { id: 'contact', label: 'Contact', href: '/contact' }
    ];

    return (
        <nav className="p-2 sticky top-0 z-50 bg-white dark:bg-black">
            <div className="max-w-10xl mx-auto flex justify-between">
                <Link to={'/'}><img src={stratumLogo} alt="Stratum Labs" className={`w-28 lg:w-36  lg:ml-10 ${theme === 'light' ? 'filter invert' : ''}`}/></Link>
                <div className="flex-1 flex justify-end space-x-4 w-full">
                {tabs.map((tab) => (
                    <Link
                    to={tab.href}
                    key={tab.id}
                    className={`text-xs md:text-sm lg:text-base font-semibold rounded-xl py-10 px-2 md:px-4 transition-all duration-300
                    `}
                    onClick={() => setActiveTab(tab.href)}
                    >
                    {tab.label}
                    </Link>
                ))}
                <button
                    className="text-xs md:text-sm lg:text-base font-semibold rounded-xl py-2 px-2 md:px-4 transition-all duration-300"
                    onClick={toggleTheme}
                >
                    {theme === 'light' ? <FiMoon /> : <FiSun />}
                </button>
                </div>
            </div>
        </nav>
    );
};

export default TopNav;
