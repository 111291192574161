import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="-mt-20 md:mt-10 p-10 text-center text-sm border-t border-t-gray-700">
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        <div>
          <h2 className="font-bold text-xl mb-2">PRODUCT</h2>
          <p className="text-gray-400 hover:text-blue-800 text-sm cursor-pointer">
          </p>
        </div>
        {/* Links Section */}
        <div>
          <h3 className="font-bold text-lg mb-2">RESOURCES</h3>
          <ul className="text-gray-400 text-sm cursor-pointer">
            <a href='/terms-of-service'>
              <li className='mb-1 hover:text-blue-800'>Terms of service</li>
            </a>
          </ul>
        </div>

        <div>
          <h3 className="font-bold text-lg mb-2">ABOUT</h3>
          <ul className="text-gray-400 text-sm cursor-pointer">
            <a href='/contact'>
              <li className="mb-1 hover:text-blue-800">Contact us</li>
            </a>
          </ul>
        </div>
      </div>
      <div className="text-center text-gray-400 text-sm mt-8">
        {/* © 2023 - {new Date().getFullYear()} Stratum Labs. All rights reserved. */}
        © {new Date().getFullYear()} Stratum Labs. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;