import React from 'react';

const services = [
  {
    title: 'Mobile App Development',
    description: 'Let us create stunning and functional iOS apps and Android App for your business that stand out.',
  },
  {
    title: 'Web App Development',
    description: 'We build responsive and feature-rich web apps for all your needs.',
  },
  {
    title: 'Software Development',
    description: 'We build software tailored to you needs.',
  },
  {
    title: 'UI/UX Design',
    description: 'Our designers create beautiful and intuitive interfaces for your apps.',
  },
  {
    title: 'App Maintenance',
    description: 'We provide ongoing support and updates to keep your apps running smoothly.',
  },
  {
    title: 'IT Support',
    description: 'Got a problem? talk to us so we can slove it for you.',
  },
];

const Service: React.FC = () => {
  return (
    <div>
      <div className="container mx-auto px-4 py-16">
        <h2 className="text-2xl lg:text-5xl font-bold text-center mb-16">Our Services</h2>
        <p className="lg:text-lg text-center mb-12">
          From streamlining small business operations to engineering complex solutions for corporations, We offer a range of services to meet your app development needs.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div key={index} className="flex flex-col items-center text-center">
              <h3 className="text-xl font-bold mb-2">{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Service;
