import React from 'react';

const Home: React.FC = () => {
  return (
    <div>
      <div className="container mx-auto px-4 py-16">
        <h2 className="text-2xl lg:text-5xl font-bold text-center mb-12">Pioneering the New Age of IT Solutions</h2>
        <p className='font-bold text-center mb-12 fadeIn'>A new age is upon us</p>
        <p className="lg:text-lg text-center mb-36">
          At Stratum Labs, we understand that technology is more than just a
          tool, it's the heartbeat of modern business. As we embrace a new age
          of digital possibilities, our commitment is to ensure that your IT
          infrastructure doesn't just keep up, but leads the way.
        </p>
      </div>
    </div>
  );
};

export default Home;
