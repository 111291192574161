import React, { useEffect } from 'react';

const TermsOfService: React.FC = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className="container mx-auto px-4 py-16">
        <h2 className="text-2xl lg:text-5xl font-bold text-center mb-12">Terms of Service</h2>

        <p className="lg:text-2xl mb-5 underline">Acceptance of Terms</p>
        <p className="lg:text-lg mb-10">
            By accessing and using the services provided by Stratum Labs ("we", "us", or "our"), you acknowledge that you have read, 
            understood, and agree to be bound by these Terms of Service ("Terms"). 
            If you do not agree with any part of these Terms, you must not use our services.
        </p>
        <p className="lg:text-2xl mb-5 underline">Description of Service</p>
        <p className="lg:text-lg mb-10">
            Stratum Labs offers a range of services including Mobile App Development, Web App Development, UI/UX Design, 
            App Maintenance, Software Development, and IT Support ("Service" or "Services"). Each Service is described in detail on our website.
        </p>
        <p className="lg:text-2xl mb-5 underline">User Accounts and Responsibilities</p>
        <p className="lg:text-lg mb-10">
            To access certain Services, you may be required to create an account. You are responsible for maintaining the security of your account and password. 
            Stratum Labs cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.
        </p>
        <p className="lg:text-2xl mb-5 underline">Modifications to the Service and Prices</p>
        <p className="lg:text-lg mb-10">
            Stratum Labs reserves the right at any time to modify or discontinue, temporarily or permanently, a Service (or any part thereof) with or without notice. 
            Prices of all Services are subject to change upon 30 days notice from us.
        </p>
        <p className="lg:text-2xl mb-5 underline">General Conditions</p>
        <p className="lg:text-lg mb-10">
            Your use of the Services is at your sole risk. The Services are provided on an "as is" and "as available" basis. We do not warrant that 
            (i) the Services will meet your specific requirements, (ii) the Services will be uninterrupted, timely, secure, or error-free, 
            (iii) the results that may be obtained from the use of the Services will be accurate or reliable, and (iv) any errors in the Services will be corrected.
        </p>
        <p className="lg:text-2xl mb-5 underline">Limitation of Liability</p>
        <p className="lg:text-lg mb-10">
            You expressly understand and agree that Stratum Labs shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, 
            including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if Stratum Labs has been advised of the possibility of such damages).
        </p>
        <p className="lg:text-2xl mb-5 underline">Copyright and Intellectual Property</p>
        <p className="lg:text-lg mb-10">
            The Services may involve the development of software, designs, and other intellectual property. Unless otherwise agreed in writing, 
            all intellectual property rights in the work produced in the course of the Services shall belong to Stratum Labs.
        </p>
        <p className="lg:text-2xl mb-5 underline">Termination</p>
        <p className="lg:text-lg mb-10">
            We may terminate or suspend your account and bar access to the Services immediately, 
            without prior notice or liability, if you breach any of the terms or conditions of these Terms.
        </p>
        <p className="lg:text-2xl mb-5 underline">Governing Law</p>
        <p className="lg:text-lg mb-10">
            These Terms shall be governed and construed in accordance with the laws of Canada, without regard to its conflict of law provisions.
        </p>
        <p className="lg:text-2xl mb-5 underline">Changes to Terms</p>
        <p className="lg:text-lg mb-10">
            We reserve the right, at our sole discretion, to modify or replace these Terms at any time. 
            We will provide at least 30 days' notice before any new terms taking effect.
        </p>
        <p className="lg:text-2xl mb-5 underline">Contact Information</p>
        <p className="lg:text-lg mb-10">
            If you have any questions about these Terms, please contact us at stratumlabs@gmail.com.
        </p>
    </div>
  );
};

export default TermsOfService;
