import React, { useState, useEffect } from 'react';
import { FaGithub, FaLink, FaGooglePlay, FaApple } from 'react-icons/fa';
import projectData from '../landingPages/Projects.json';

type project = {
  id: string;
  title: string;
  description: string;
  techStackImgUrl: string;
  alt: string;
  path: string;
  githubUrl: string;
  webUrl: string;
  appleStoreUrl: string;
  googleStoreUrl: string;
  status: string;
  backgroundImage: string;
  logoColor: string;
};

type ProjectData = { Projects: project[] };

const Projects: React.FC = () => {
  const [projects, setProjects] = useState<project[]>([]);

  useEffect(() => {
    const data: ProjectData = projectData as ProjectData;
    const projectsArray = data.Projects;
    setProjects(projectsArray);
  }, []);

  return (
    <div>
      <div className="container mx-auto px-4">
        <h2 className="text-3xl lg:text-4xl font-bold text-center mb-12">
          See Our Work
        </h2>
        <p className="text-xl text-center mb-12">
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
          {projects.map((project, index) => (
            <div
              key={index}
              className="bg-gray-100 dark:bg-gray-700/30 p-8 pb-0 rounded-3xl shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
              <div className="h-44 rounded-2xl flex items-center justify-center">
                <img src={`/${project.backgroundImage}`} alt={project.title} className={`max-h-44 ${project.logoColor === 'black' ? 'dark:filter dark:invert' : ''}`}  />
              </div>
              <div className="p-4 pl-0">
                <h3 className="text-xl font-semibold mb-2">{project.title}</h3>
                <p className="text-sm mb-4">{project.description}</p>
                <div className="lg:mt-2 flex justify-end items-center space-x-1 lg:space-x-3">
                  {project.webUrl && (
                    <a
                      href={project.webUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:text-gray-500 text-xl link"
                    >
                      <FaLink className="inline mb-1 ml-4 lg:ml-0" />
                      <span className="text-sm ml-5 lg:ml-0"></span>
                    </a>
                  )}
                  {project.githubUrl && (
                    <a
                      href={project.githubUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:text-gray-500 text-xl link"
                    >
                      <FaGithub className="inline mb-1 ml-3 lg:ml-0" />
                      <span className="text-sm"></span>
                    </a>
                  )}
                  {project.appleStoreUrl && (
                    <a
                      href={project.appleStoreUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:text-gray-500 text-xl link"
                    >
                      <FaApple className="inline mb-1 ml-2 lg:ml-0" />
                      <span className="text-sm"></span>
                    </a>
                  )}
                  {project.googleStoreUrl && (
                    <a
                      href={project.googleStoreUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:text-gray-500 text-xl link"
                    >
                      <FaGooglePlay className="inline mb-1 ml-3 lg:ml-0" />
                      <span className="text-sm"></span>
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
  