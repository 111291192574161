import React, { useState } from "react";

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({ name: "", email: "", phone: "", message: "" });
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [messageVisible, setMessageVisible] = useState(false);

  const handleChange = (e: { target: { name: any; value: any } }) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    fetch("/api/sendEmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Email sent successfully") {
          // Clear the form fields
          setFormData({ name: "", email: "", phone: "", message: "" });
          setConfirmationMessage("Your message has been sent successfully!");
          setMessageVisible(true);
          setTimeout(() => {
            setMessageVisible(false);
          }, 3000);
        } else {
          setConfirmationMessage(
            "Failed to send message. Please try again later."
          );
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setConfirmationMessage("An error occurred. Please try again later.");
      });
  };
  return (
    <div className="flex flex-col items-center justify-center min-h-screen md:-mt-40 lg:-mt-32 p-6 md:p-0">
        <h1 className="text-2xl text-center font-bold mb-6">Leave us a message</h1>
          {confirmationMessage && (
              <div className={`confirmation-message ${ !messageVisible ? "hidden" : ""} bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative`} role="alert">
                  <strong className="font-bold">Success!</strong>
                  <span className="block sm:inline">{confirmationMessage}</span>
              </div>
          )}
          <form onSubmit={handleSubmit} className="w-full max-w-xl lg:mr-32">
              <div className="md:flex md:items-center mb-10">
                  <div className="md:w-1/3">
                      <label className="block text-sm font-bold mb-2" htmlFor="name"> Name </label>
                  </div>
                  <div className="md:w-4/5">
                      <input className="bg-transparent appearance-none border border-gray-200 rounded w-full py-2 px-4  leading-tight focus:outline-none  focus:border-orange-900" onChange={handleChange}
                          id="name" name="name" type="text" placeholder="Your name" required autoComplete="off"/>
                  </div>
              </div>
              <div className="md:flex md:items-center mb-10">
                  <div className="md:w-1/3">
                      <label className="block text-sm font-bold mb-2" htmlFor="email">Email </label>
                  </div>
                  <div className="md:w-4/5">
                      <input className="bg-transparent appearance-none border border-gray-200 rounded w-full py-2 px-4  leading-tight focus:outline-none  focus:border-orange-900" onChange={handleChange}
                        id="email" name="email" type="email" placeholder="Your email" required autoComplete="off"/>
                  </div>
              </div>
              <div className="md:flex md:items-center mb-10">
                  <div className="md:w-1/3">
                      <label className="block text-sm font-bold mb-2" htmlFor="phone">Phone </label>
                  </div>
                  <div className="md:w-4/5">
                      <input className="bg-transparent appearance-none border border-gray-200 rounded w-full py-2 px-4  leading-tight focus:outline-none  focus:border-orange-900" onChange={handleChange}
                        id="phone" name="phone" type="text" placeholder="Your phone number (optional)" autoComplete="off"/>
                  </div>
              </div> 
              <div className="md:flex md:items-center mb-10">
                  <div className="md:w-1/3">
                      <label className="block text-sm font-bold mb-2" htmlFor="message" > Message </label>
                  </div>
                  <div className="md:w-4/5">
                      <textarea className="bg-transparent appearance-none border border-gray-200 rounded w-full py-2 px-4 leading-tight focus:outline-none focus:border-orange-900" onChange={handleChange}
                        id="message" name="message" placeholder="Your message" required autoComplete="off">  
                      </textarea>
                  </div>
              </div>
              <div className="md:flex md:items-center ml-24 md:-ml-12 lg:ml-16">
                  <div className="md:w-1/3"></div>
                  <div className="md:w-2/3">
                      <button className="shadow bg-transparent border hover:bg-orange-900 focus:shadow-outline focus:outline-none  font-bold py-2 px-4 rounded md:ml-10" type="submit">
                        Send Message
                      </button>
                  </div>
              </div>
          </form>
    </div>
  );
};

export default Contact;
