import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopNav from '../components/TopNav';
import Footer from '../components/Footer';
import Home from './landingPages/Home';
import Service from './landingPages/Service';
import Projects from './landingPages/Projects';
import Contact from './landingPages/Contact';
import TermsOfService from './other pages/TermsOfService';
import '../css/App.css';

const App: React.FC = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <TopNav />
        <div className="pt-auto flex-1">
          <Routes>
            <Route path="/" element={<HomePage />} />
            {/* <Route path="/about" element={<AboutPage />} /> */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};



const HomePage: React.FC = () => (
  <div className="flex-1">
    <section id="home" className="h-screen flex items-center justify-center md:my-0">
      <Home />
    </section>
    <section id="services" className="h-screen flex items-center justify-center mb-96 md:-my-32">
      <Service />
    </section>
    <section id="projects" className="h-screen flex items-center justify-center mb-72 md:-my-32">
      <Projects />
    </section> 
  </div>
);

export default App;
